import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import backend from "../api";
import CryptoJS from "crypto-js";

export const IBCCHome = () => {
  const location = useLocation();

  // Use URLSearchParams to extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get("code");
  const _id = queryParams.get("serid");
  const [userInfo, setUserInfo] = useState("");
  const [percent, setPercent] = useState("");
  const [error, setError] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState("");

  useEffect(async () => {
    const secretKey = process.env.REACT_SECRET_KEY;
    const fixedIV = "1234"; // Replace with your own fixed IV

    const decryptedBytes = CryptoJS.AES.decrypt(
      user,
      CryptoJS.enc.Utf8.parse(secretKey),
      {
        iv: CryptoJS.enc.Utf8.parse(fixedIV),
        mode: CryptoJS.mode.CFB,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    // Convert the decrypted bytes to a string
    const decryptedText = await decryptedBytes.toString(CryptoJS.enc.Utf8);
    // console.log("Decrypted", decryptedText);
    if (decryptedText) setUserID(decryptedText);
    else setUserID(_id);
  }, [user, _id]);

  useEffect(() => {
    if (userID !== "") {
      backend
        .get(`/user?_id=${userID}`)
        .then((res) => {
          let data = res.data.data[0];
          setUserInfo(data);
          setLoading(false);
          setPercent(
            parseFloat(
              (parseInt(data.obtainedMarks) / parseInt(data.totalMarks)) * 100
            ).toFixed(0)
          );
          setError(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    }
  }, [userID]);

  return loading ? (
    <div>Loading</div>
  ) : error ? (
    <p className="closing-title">Invalid Link - No Certificate found</p>
  ) : (
    <div className="verify-container">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <img
          className="img-fluid"
          src={
            "https://consmofa-assets.s3.ap-south-1.amazonaws.com/ibcclogo.jpeg"
          }
          style={{ width: 200, height: 200 }}
          alt="Logo"
        />
      </div>

      <h1 className="verify-title">
        Welcome to IBCC Portal for Digital Verification
      </h1>
      <h2 className="verify-subtitle">
        This Certificate is attested by IBCC to{" "}
        <span className="text-uppercase">{userInfo.name} . </span>
      </h2>
      <div className="row">
        <div className="col-lg-3"></div>
        <div className="col-lg-6 table-class align-items-center justify-content-center">
          <br />
          <table className="verify-table">
            <tbody>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Student Name</b>
                </td>
                <td className="td-values text-uppercase">
                  <b>{userInfo.name}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Father Name</b>
                </td>
                <td className="td-values text-uppercase">
                  <b>{userInfo.fatherName}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Roll No</b>
                </td>
                <td className="td-values">
                  <b>{userInfo.rollNum}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Certificate/Program</b>
                </td>
                <td className="td-values">
                  <b>
                    {userInfo.certificate} / {userInfo.program}
                  </b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Board</b>
                </td>
                <td className="td-values">
                  <b>{userInfo.board}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Group</b>
                </td>
                <td className="td-values">
                  <b>{userInfo.group}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td></td>
                <td className="td-values">
                  <b>Passing Year - Session</b>
                </td>
                <td className="td-values">
                  <b>{userInfo.passingYear}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Marks</b>
                </td>
                <td className="td-values">
                  <b>
                    {userInfo.obtainedMarks}/{userInfo.totalMarks} ({percent}
                    %)
                  </b>
                </td>
                <td className="td-empty"></td>
              </tr>
              <tr>
                <td className="td-empty"></td>
                <td className="td-values">
                  <b>Reference No.</b>
                </td>
                <td className="td-values">
                  <b>{userInfo.serialId}</b>
                </td>
                <td className="td-empty"></td>
              </tr>
            </tbody>
          </table>
          <p></p>
          <p className="subtitle-text">
            Note - Please compare the certificate data from the above mention
            data stored in IBCC database, provide your email address and get the
            verification reply from info@ibcc.edu.pk.
          </p>
        </div>
        <div className="col-lg-3"></div>
      </div>
      <p className="closing-title">
        For More Information, You can visit IBCC Office, IBCC Website or Contact
        IBCC UAN Number 051- 111 114 222
      </p>
    </div>
  );
};
