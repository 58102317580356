import axios from "axios";

const backend = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BACKEND
      : process.env.REACT_APP_BACKEND
});

backend.interceptors.request.use(
  (config) => {
    let user = "";
    if (!user)
      user = localStorage.getItem("tg_user")
        ? JSON.parse(localStorage.getItem("tg_user"))
        : null;
    if (user) config.headers = { Authorization: `Bearer ${user.token}` };
    return config;
  },
  (error) => Promise.reject(error)
);

export default backend;
