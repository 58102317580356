import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./Context";
import { Toaster } from "react-hot-toast";
import "react-responsive-modal/styles.css";

import { IBCCHome } from "./Admin/IBCCHome";
function App() {
  return (
    <div className="App">
      <Toaster />
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            {/* <Route path="/" element={<h1> 404 not found </h1>} /> */}
            <Route path="/" element={<IBCCHome />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
